import React, { useEffect, useState,useRef,useLayoutEffect } from 'react'
import { Link,NavLink } from 'react-router-dom';
import Logo from '../Pictures/logo.jpg'
import LogoTransparent from '../Pictures/logo_low.png';
import { Button, Dimmer, Icon, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { Events } from '../Data/Events';
import { Popover } from 'antd';


const Navigation = () => {

  const [sticky, setSticky] = useState(false);
  const [open,setOpen] = useState(false);

  useEffect(()=>{
    const navScroll =()=>{
      if(window.scrollY>20){
        setSticky(true)
      }else{
        setSticky(false)
      }
    };
    window.addEventListener('scroll',navScroll);
    return ()=> window.removeEventListener('scroll',navScroll)
  })
  const navbarHeight = sticky ? '90px' : '100px';


  const menuItemsRef = useRef(null);
  const backgroundRef = useRef(null);
  const menuButtonRef = useRef(null);
  const mobileNavRef = useRef(null);

  useLayoutEffect(() => {
    const menuItems = menuItemsRef.current;
    const background = backgroundRef.current;
    const menuButton = menuButtonRef.current;
    const mobileNav = mobileNavRef.current

    menuItems.style.transform = 'translateY(-500px)';
    background.style.backgroundColor = 'white';
    menuButton.style.color = 'black';
    mobileNav.style.height = '5px'
  }, []);

  function showMenu() {
    const menuItems = menuItemsRef.current;
    const background = backgroundRef.current;
    const menuButton = menuButtonRef.current;
    const mobileNav = mobileNavRef.current

    if (menuItems.style.transform === 'translateY(-500px)') {
      menuItems.style.transform = 'translateY(0px)';
      background.style.backgroundColor = '#171717';
      menuButton.style.color = 'white';
      mobileNav.style.height = '400px'
    } else {
      menuItems.style.transform = 'translateY(-500px)';
      background.style.backgroundColor = 'white';
      menuButton.style.color = 'black';
      mobileNav.style.height = '5px'

    }
  }

  const close =()=>{
    const menuItems = menuItemsRef.current;
    const background = backgroundRef.current;
    const menuButton = menuButtonRef.current;
    
    menuItems.style.transform = 'translateY(-500px)';
    background.style.backgroundColor = 'white';
    menuButton.style.color = 'black';
  }

  const [page] = useState(window.location.pathname)

  const mobile = useMediaQuery({query:'(max-width: 1110px)'})

  const showModal = ()=>{
    setOpen(true)
  }
  const closeModal = ()=>{
    setOpen(false)
  }


  return (
    
    <div>
 {/*     
      <div id="topContact">
       <Icon name='mail' inverted/> <a href="mailto:ghanaunion.cz@gmail.com" target="_blank"rel='noreferrer'>ghanaunion.cz@gmail.com</a>
       {mobile?<></>:<>
       <Icon name='instagram' inverted/> <a href="https://www.instagram.com/ghanaunioncz/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==" target="_blank"rel='noreferrer'>@ghanaunioncz</a>
       
       <Icon name='facebook' inverted/> <a href="https://www.facebook.com/GHunioncz" target="_blank"rel='noreferrer'>GHunioncz</a>
       </>
  }

    </div>

    */}
 
   <nav id={sticky?'sticky':'nav'} style={{height: navbarHeight}}>
        <Link reloadDocument to={'/'} style={{margin:'0px 2vw 0px 0px'}}><img src={sticky?Logo:LogoTransparent} alt='logo' style={{width:'6.5vh'}} /></Link>
        <Link reloadDocument to={'/'} style={{marginLeft:'0',textDecoration:`${page==='/'?'underline':''}`}}>Home</Link>
        <Link reloadDocument to={'/about'} style={{textDecoration:`${page==='/about'?'underline':''}`}}>About Us</Link>
        <Link reloadDocument to={'/events'} style={{textDecoration:`${page==='/events'?'underline':''}`}}>Events</Link>
        <a href='#form'>Contact Us</a>
        <a href='/#partners'>Partners</a>

        <Link reloadDocument to={'/gallery'} style={{textDecoration:`${page==='/gallery'?'underline':''}`}}>Gallery</Link>
        <Link to={'/joinus'} style={{marginLeft:'20px',textDecoration:`${page==='/joinus'?'underline':''}`,color:'gold'}} className='navJoin' reloadDocument>Join Us</Link>

      <div id="topContact">
        <Popover content={'ghanaunion.cz@gmail.com'}>
        <a href="mailto:ghanaunion.cz@gmail.com" target="_blank"rel='noreferrer' className='icons'><Icon name='mail' circular   inverted size='large'/></a>
        </Popover>
       {mobile?<></>:<>

       <Popover content={'ghanaunioncz'}>
       <a href="https://www.instagram.com/ghanaunioncz/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="  target="_blank"rel='noreferrer'><Icon name='instagram' circular inverted color='black' size='large'/></a>
       </Popover>
       
       
       <Popover content={'GHunioncz'}>
       <a href="https://www.facebook.com/GHunioncz" target="_blank"rel='noreferrer' className='icons'><Icon name='facebook' circular inverted  size='large'/></a>
      </Popover>
       </>
  }

      </div>

    </nav>
    

    <div id="mobileNav" className={sticky?'mobileSticky':''} ref={mobileNavRef}>
        <div id="background" className={sticky?'mobileStickyBg':''} ref={backgroundRef}>
       <Link to={'/'}><span id="mobileLogo"><img src={LogoTransparent} width="30px" alt='logo'/></span></Link>
        <span id="menuButton" onClick={showMenu} ref={menuButtonRef} style={{fontSize:'24px'}}>{'\u2630'}</span>
        </div>
        <div id="menuItems" onClick={close} ref={menuItemsRef}>
        <NavLink to={'/'} reloadDocument>Home</NavLink>
        <NavLink to={'/about'} reloadDocument>About Us</NavLink>
        <NavLink to={'/events'} reloadDocument>Events</NavLink>
        <NavLink to={'/joinus'} reloadDocument>Join Us</NavLink>
        <NavLink to={'/gallery'} reloadDocument>Gallery</NavLink>
        <a href="#form">Contact Us</a>
        <a href="/#partners">Partners</a>

        </div>
    </div>

{/*

      <Modal size='huge' closeOnEscape={true} onClose={closeModal} open={open}>
        <ModalHeader>End of Year Party 25/12/2023</ModalHeader>
        <ModalContent style={{textAlign:'center'}}>
  
  <a href={Events[4].flyer}><img alt='flyer' src={Events[4].flyer} width={'50%'} /></a>
      <Link to={Events[4].location} target='_blank'><h2 style={{color:'black'}}><i >{Events[4].location}</i></h2></Link>
      <br/>
      <br/>
      <h2>{Events[4].summary}</h2>


  
    </ModalContent>
        <ModalActions>
          <Button onClick={closeModal} neutral>I'm Not Interested </Button>
          <Button positive><Link to={Events[4].form} target='_blank' style={{color:'white'}}>I'm Interested</Link></Button>
        </ModalActions>
      </Modal>


*/}
    </div>

  )
}

export default Navigation
