import Humanitas from '../Pictures/Partners/Humanitas.jpg'

export const Partners = [
    {
        id:1,
        name:'Humanitas Africa',
        description: 'Humanitas Afrika was founded by a handful of Africans and Czechs in Prague in 2000 to promote mutual tolerance and understanding between Czechs and Africans, and raise awareness about Africa, culture in the Czech society. Generally, it promotes and enhances good and friendly relations between Czechia and Africa, and by extension between Europe and Africa. \n In 2008, it established the African Information Centre with an African library where various African books ranging from history, politics, literature, economics culture can be borrowed by individuals or read in the library.',
        web:'https://www.humanitasafrika.cz/en/',
        thumb: Humanitas
    },
]