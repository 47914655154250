import React, { useState } from 'react'
import { gallery } from '../Data/Gallery'
import { useParams } from 'react-router-dom'
import ReactImageGallery from 'react-image-gallery'
import { useMediaQuery } from 'react-responsive'




export default function EventGallery() {
    const {id} = useParams()
    const [currentOrientation,setCurrentOrientation] =useState(window.screen.orientation.type)


    const categories = new Set(gallery.map((image)=>(
        image.eventId
      )))
    
      const byEvent = [...categories].map((category) =>
      gallery.filter((item) => item.eventId === category)
    );

    
    const handleOrientationChange = async () => {
      const newOrientation =
        currentOrientation === 'portrait-primary'
          ? 'landscape-primary'
          : 'portrait-primary';
  
      try {
        if (window.screen.orientation) {
          await window.screen.orientation.lock(newOrientation);
          setCurrentOrientation(newOrientation);
        } else {
          console.log('Screen orientation API not supported.');
        }
      } catch (error) {
        console.error('Failed to lock orientation:', error);
      }
    };

    const mobile = useMediaQuery({query:'(max-width:1130px)'})

  return (
    <>
    <div className='pageBanner' style={{height:`${mobile?'65px':'90px'}`,backgroundColor:'#171717'}}>
        <div className='pageBannerText'>
          <br/>

        </div>
      </div>
      <div id='fullGallery' style={{textAlign:'center'}}>
        <ReactImageGallery items={byEvent[id-1]} lazyLoad showBullets onScreenChange={handleOrientationChange}/>
      </div>



    </>
  )
}
