import React from 'react'


export default function Register() {
  let bannerStyle ={
    backgroundColor: `#171717`,
    height:'90px'
  }
  return (
    <div>
      <div className='pageBanner' style={bannerStyle}>
  </div>
      <div id='form'>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdODe8-oQPJbbT-7RfE8v3JEwgKtT5PGoA-8KB_a_VyCb_17g/viewform?embedded=true"  width='600' height="824" frameborder="0" marginheight="0" marginwidth="0" title='form'>Loading…</iframe>      </div>
    </div>
  )
}
