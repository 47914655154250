import React from 'react'
import { Executives as executives } from '../Data/Executives'
import { Zoom } from 'react-awesome-reveal'
import { Divider, Icon } from 'semantic-ui-react'
import bannerPic1 from '../Pictures/banner1.jpg'

const Executives = () => {

  let bannerStyle ={
    backgroundImage: `url(${bannerPic1})`,
  }
  const execs = executives.map((exec)=>
    <Zoom triggerOnce key={exec.id}>
    <li><img alt='portrait' src={exec.portrait} style={{width:'127px',borderRadius:'10px',height:''}}/><span style={{fontSize:'24px'}}>{exec.position}</span>
    <br/>
    {exec.name}
    <br/>
    <Icon name='phone'/><a href={`tel:${exec.number}`} target='_blank' rel='noreferrer'> {exec.number}</a>
    <br/>
    <br/>
    </li>
    </Zoom>
  )


  return (
<>
<div className='pageBanner' style={bannerStyle}>
        <div className='pageBannerText'>
          <Divider horizontal style={{color:'white'}}><h1>Executives</h1></Divider>
          <br/>
          <a href='#executives' rel='noreferrer' style={{textDecoration:'none',color:'gold'}}><Icon name='arrow circle down' link size='big'/></a>
        </div>
      </div>

<div id='about'>
   

      <div id='executives' >
       <Divider horizontal><h2>Executives</h2></Divider>
       <br/>
        <ul>
        {execs}
        </ul>
      </div>


      
    </div>
    </>
    
  )
}

export default Executives
