import React from 'react'
import { News } from '../Data/News'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'


export default function NewsDetails() {
    const {id} = useParams()

    const mobile = useMediaQuery({query:'(max-width:1130px)'})



  return (
    <>
    <div className='pageBanner' style={{height:`${mobile?'65px':'90px'}`,backgroundColor:'#171717'}}>
    <div className='pageBannerText'>

      

    </div>
  </div>



    <div id='details'>
      
    <img alt='thumbnail' src={News[Number(id)-1].image} width='200px'/>
    <h1>{News[Number(id)-1].headline}</h1>

    <div>{News[Number(id)-1].details}</div>

  </div>
  </>
  )
}
