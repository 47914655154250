import AddoDjan from '../Pictures/Executives/Addo-Djan-min.jpg'
import Yeboah from '../Pictures/Executives/Yeboah.jpg'
import Odei from '../Pictures/Executives/Odei.jpg'
import Bampoe from '../Pictures/Executives/Bampoe.jpg'
import Sarfo from '../Pictures/Executives/Sarfo.jpg'



export const Executives = [
    
    {
        id:'1',
        position: 'President',
        name: 'Ing. Isaac Bediako Odei, MBA',
        number: '+420 739 545 240',
        portrait: Odei,
    },
    {
        id:'2',
        position: 'Vice President',
        name: 'Ing. Samuel Saforo Bampoe, MBA',
        number: '+420 608 254 479',
        portrait: Bampoe,
    },

    {
        id:'3',
        position: 'Secretary',
        name: 'Ing. Augustine Yeboah, MBA',
        number: '+420 776 116 128',
        portrait: Yeboah,
    },
    {
        id:'4',
        position: 'Treasurer',
        name:'F. Thomas Addo-Djan, Dip. Mgmt.',
        number: '+420 605 510 444',
        portrait: AddoDjan,
    },
    {
        id:'5',
        position: 'Brno Coordinator',
        name:'Kwame Adu Sarfo',
        number: '+420 737 811 706',
        portrait: Sarfo,
    },





]