import React from 'react'
import { Executives } from '../Data/Executives'
import { useParams } from 'react-router-dom'

export default function AboutExcec() {
  const {id} = useParams()


  return (
    <div id='details'>
    <img alt='thumbnail' src={Executives[Number(id)-1].portrait} width='200px'/>
    <h1>{Executives[Number(id)-1].position}</h1>
    <h1>{Executives[Number(id)-1].name}</h1>
    <h3>{Executives[Number(id)-1].about}</h3>
    <a href={`tel:${Executives[Number(id)-1].number}`}>Number: {Executives[Number(id)-1].number}</a>
    <br/>
    <a href={`mailto:${Executives[Number(id)-1].email}`}>Email: {Executives[Number(id)-1].email}</a>
    </div>
  )
}
