import React from 'react'
import { gallery } from '../Data/Gallery'
import { Link } from 'react-router-dom'
import { Divider, Icon, Loader } from 'semantic-ui-react'
import bannerPic1 from '../Pictures/banner1.jpg'

export default function Gallery() {

  let bannerStyle ={
    backgroundImage: `url(${bannerPic1})`,
  }


  const categories = new Set(gallery.map((image)=>(
    image.category
  )))

  const byCategory = [...categories].map((category) =>
  gallery.filter((item) => item.category === category)
);
return (

  <>

<div className='pageBanner' style={bannerStyle}>
        <div className='pageBannerText'>
          <Divider horizontal style={{color:'white'}}><h1>Gallery</h1></Divider>
          <Link reloadDocument to={'/gallery/all'} id='joinUs'>View All</Link>
          <br/><br/>
          <a href='#gallery' rel='noreferrer' style={{textDecoration:'none',color:'gold'}}>View Categories <Icon name='arrow circle down' link  size='big' ></Icon></a>
          <br/><br/>
          

        </div>
      </div>

    <div id='gallery' style={{textAlign:'center',paddingTop:'80px'}}>
    <div id='galleryCategories'>
      {
        byCategory.map(category=>(
          <div style={{marginBottom:'100px'}} key={category.id}>
          <Link to={`/gallery/${category[0].id}`} reloadDocument>
            {category[0].thumbnail?<img src={category[0].thumbnail} alt='thumbnail'/>:<Loader active>Loading</Loader>}
            <h1>{category[0].category}</h1>
          </Link>
          </div>

        ))
      }
    </div>

    </div>
    </>
  )
}
