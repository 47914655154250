import React from 'react'
import { News } from '../Data/News'
import { Link } from 'react-router-dom'
import { Zoom } from 'react-awesome-reveal'
import { Divider, Container, Icon } from 'semantic-ui-react'
import bannerPic1 from '../Pictures/banner1.jpg'

export default function News1() {


  let bannerStyle ={
    backgroundImage: `url(${bannerPic1})`,
  }
  
  const sortedNews = [...News]
  sortedNews.sort((a,b)=>a.sort-b.sort)
    
    
    
      const news = sortedNews.map((News)=>
        <div>
    <Zoom triggerOnce={true}>
          <Link to={`/newsdetails/${News.id}`} style={{color:`${News.sort<new Date()? 'red':'black'}
    `,textDecoration:'none'}} reloadDocument>
        <img alt='thumbnail' src={News.image} width={'200px'} loading='lazy'/>
        <h2>{News.headline}</h2>
    
    
        </Link>
    </Zoom>
        <br/>
        </div>
      )
    
  return (
<>
    <div className='pageBanner' style={bannerStyle}>
    <div className='pageBannerText'>
      <Divider horizontal style={{color:'white'}}><h1>News</h1></Divider>
      <br/>
      <a href='#news' rel='noreferrer' style={{textDecoration:'none'}}><Icon name='arrow circle down' link  size='big' ></Icon></a>

    </div>
  </div>
    <div style={{marginTop:'100px'}}>
    <Divider horizontal><h2>News</h2></Divider>
    <br/>
      <div style={{textAlign:'center'}}>
      <Container id='news'>{news}</Container>
      </div>
    </div>
    </>
  )
}
