import React,{useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import { gallery } from '../Data/Gallery'
import  ImageGallery  from 'react-image-gallery'
import { useMediaQuery } from 'react-responsive'



export default function FullGallery() {


  const [currentOrientation, setCurrentOrientation] = useState(window.screen.orientation.type);



    const {id} = useParams()
    const categories = new Set(gallery.map((image)=>(
        image.id
      )))
      const byCategory = [...categories].map((category) =>
      gallery.filter((item) => item.id === category)
    );




    const handleOrientationChange = async () => {
      const newOrientation =
        currentOrientation === 'portrait-primary'
          ? 'landscape-primary'
          : 'portrait-primary';
  
      try {
        if (window.screen.orientation) {
          await window.screen.orientation.lock(newOrientation);
          setCurrentOrientation(newOrientation);
        } else {
          console.log('Screen orientation API not supported.');
        }
      } catch (error) {
        console.error('Failed to lock orientation:', error);
      }
    };


    const mobile = useMediaQuery({query:'(max-width:1130px)'})


  return (

    <>
    <div className='pageBanner' style={{height:`${mobile?'65px':'90px'}`,backgroundColor:'#171717'}}>
        <div className='pageBannerText'>
          <br/>

        </div>
      </div>

    <div id='fullGallery' style={{textAlign:'center'}}>
      {id === 'all'
      ?<Link reloadDocument id='joinUs' to={'/gallery'}>View Categories</Link>
      :<Link reloadDocument id='joinUs' to={'/gallery/all'}>View All</Link>
      }
      <br/>
      <br />
      {id==='all'
      ?<ImageGallery items={gallery} lazyLoad={true} showIndex showBullets onScreenChange={handleOrientationChange}/>
      :<ImageGallery items={byCategory[id-1]} lazyLoad={true} showIndex showBullets onScreenChange={handleOrientationChange} />
      }
    </div>
    </>
  )
}
