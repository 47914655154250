import React, { useState } from 'react'
import {AutoComplete, Button, Card, Checkbox, DatePicker, Input, Radio, Result, Space, Spin, Steps, Upload,message } from 'antd'
import { Link } from 'react-router-dom'
import { Divider,Icon,Form, Dropdown, Header } from 'semantic-ui-react'
import bannerPic1 from '../Pictures/banner1.jpg'
import membershipForm from '../Data/Registration Form.DOCX'
import { Loading3QuartersOutlined, LoadingOutlined } from '@ant-design/icons'
import FormItem from 'antd/es/form/FormItem'
import { Nationalities } from '../Data/Nationalities'
import TextArea from 'antd/es/input/TextArea'
import { useForm } from 'antd/es/form/Form'
import emailjs from '@emailjs/browser'
import { useMediaQuery } from 'react-responsive'


export default function FormPage() {

    let bannerStyle ={
        backgroundImage: `url(${bannerPic1})`,
      }

      const [current,setCurrent] = useState(0)
    const [formData, setFormData] = useState({
        // Initial state for all form fields
        name: '',
        sex: '',
        dob: '',
        address: '',
        tel: '',
        email: '',
        nation: '',
        home: '',
        profession: '',
        mStatus: '',
        mName: '',
        cCount: '',
        cNames: '',
        ecName: '',
        ecHome: '',
        ecTel: '',
        ecEmail: '',
        memberType: '',
        reason: '',
        member1: '',
        member2: '',
        agreement1: false,
        agreement2: false,
      });



    const stepItems = [
        {
            id:1,
            title:'Personal Information',
            icon: current==0?<LoadingOutlined/>:<Icon name='address card'/>
            

        },
        {
            id:2,
            title:'Optional Details',
            description:'Optional details can be left blank',
            icon: current==1?<LoadingOutlined/>:<Icon name='user'/>
        },
        {
            id:3,
            title:'Emergency',
            description:'Emergency Contact Information',
            icon: current==2?<LoadingOutlined/>:<Icon name='hospital'/>

        },
        {
            id:4,
            title:'Membership category',
            description:'Membership type',
            icon: current==3?<LoadingOutlined/>:<Icon name='list'/>

        },

        {
            id:5,
            title:'Agreement',
            description:'Agreement',
            icon: current==4?<LoadingOutlined/>:<Icon name='file'/>
        },
        {
            id:6,
            title:'Agreement',
            description:'Agreement',
            icon: current==5?<LoadingOutlined/>:<Icon name='file'/>
        },
        {
            id:7,
            title:'Confirmation',
            description:'Edit any mistakes',
            icon: current==6?<LoadingOutlined/>:<Icon name='checkmark'/>
        },

    ]

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };

      const handleStepChange = (request) => {
        if (request === 0 && current > 0) {
          setCurrent(current - 1);
        } else if (request === 1 && current < stepItems.length - 1) {
         
          if (validateStep(current)) {
            setCurrent(current + 1);

          } else {
            message.error('Please fill in all required fields for this step.');
          }
        }
      };




  const validateStep = (step) => {
    // Implement validation logic based on step and required fields
    switch (step) {
      case 0:
        return (
          name.length>2 &&
          sex &&
          dob &&
          address.length>4 &&
          tel &&
          email.includes('@')&&
          nation
        );
      case 1:
          return(true)

      case 2:
        return(
        ecName.length>2&&
        ecHome&&
        ecTel&&
        ecEmail.includes('@')
        );

      case 3:
        if(memberType == 'Associate Member'){
          return (reason,member1,member2)
        }else{return(memberType)};
      case 4:
        return (agreement1);
      case 5:  
        return(agreement2);

      case 90:
        return (
          name &&
          sex &&
          dob &&
          address &&
          tel &&
          email&&
          ecName&&
          ecHome&&
          ecTel&&
          ecEmail
        )
        
      default:
        return(true);
    }
  };

    const changeProgress = (request)=> {
        if(request==0){
            setCurrent(current-1)
        }else{
            setCurrent(current+1)
        }
    }

    const [name,setName] = useState()
    const [sex,setSex] = useState()
    const [dob,setDob] = useState()
    const [address,setAddress] = useState()
    const [tel,setTel] = useState()
    const [email,setEmail] = useState()
    const [nation,setNation] = useState()

    const [home,setHome] = useState()
    const [profession,setProfession] = useState()
     
    const [mStatus,setMStatus] = useState()
    const [mName,setMName] = useState()
    const [cCount,setCCount] = useState()
    const [cNames,setCnames] = useState()

    const [ecName,setEcName] = useState()
    const [ecHome,setEcHome] = useState()
    const [ecTel,setEcTel] = useState()
    const [ecEmail,setEcEmail] = useState()


    const [memberType,setMemberType] = useState()
    const [reason,setReason] = useState()
    const [member1,setMember1] = useState()
    const [member2,setMember2] = useState()


    const [agreement1,setAgreement1] = useState(false)
    const [agreement2,setAgreement2] = useState(false)


    const part1 = [name,sex,dob,address,tel,email,nation]


const changer = (value)=>{
  if(validateStep(value)){
    setCurrent(value)
  }
}

const [completed,setCompleted] = useState(false)
const [sending,setSending] = useState(false)
const [errorMessage,setErrorMessage] = useState(false)



const submitForm = async (event) => {
    event.preventDefault()

    if(validateStep(90)){
    try {
      // Validate form fields before submission
      const formData = new FormData();
      formData.append('Timestamp', new Date().toLocaleString() );
      formData.append('Full Name', name );
      formData.append('Sex',sex );
      formData.append('Date of birth', dob );
      formData.append('Contact Address', address );
      formData.append('Telephone Number',tel );
      formData.append('Nationality', nation );
      formData.append('Hometown/Country',home?home:'' );
      formData.append('Profession/Occupation',profession?profession:'' );
      formData.append('Marital Status at registration date',mStatus?mStatus:'' );
      formData.append('Number of children',cCount?cCount:'');
      formData.append('Children names', cNames?cNames:'');
      formData.append('Spouse name', mName?mName:'');
      formData.append('Emergency contact name',ecName );
      formData.append('Emergency contact hometown',ecHome );
      formData.append('Emergency contact telephone', ecTel);
      formData.append('Emergency contact email', ecEmail);
      formData.append('Category of membership',memberType)
      formData.append('Reasons for joining the Union',reason?reason:'');
      formData.append('Name of Member 1',member1?member1:'');
      formData.append('Name of Member 2',member2?member2:'');
      formData.append('empty','Agree');
      formData.append('empty2','Agree')
      formData.append('empty3','')
      formData.append('Email Address',email)

      setSending(true)




      const response = await fetch('https://script.google.com/macros/s/AKfycbwE65ZlL1uFAOzS382Hpu3vW4ehvjBh2LBLFaIxXJX-szz-hFCU8OykWGYyzpqZ7dSf/exec', {
        method: 'POST',
        body: formData,
      });


      if (!response.ok) {
        message.error(response.status)
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = response; // Assuming response is JSON
      message.success('Form submitted successfully!');
      setCompleted(true)
      emailjs.send(
        process.env.GMAIL_SERVICE_ID,
        'template_2o606vy',
        {name:name,
        email:email
        },
        process.env.EMAILJS_APP_PUBLIC_KEY
      )
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Error submitting form');
      setErrorMessage(true)
      setSending(false)
    } finally {
      
    }
  } else {
    message.error('Please fill in all required fields before submitting.');
  }
};



const mobile = useMediaQuery({query:'(max-width:1130px)'})


  return (
    <>
        <div className='pageBanner' style={bannerStyle}>
        <div className='pageBannerText'>
          <Divider horizontal style={{color:'white'}}><h1>Membership Form</h1></Divider>
          <br/>

        </div>
      </div>

<div style={{padding:'50px'}}>
      {completed?<></>:<Steps current={current} items={stepItems} on/>}
      <br/>

{ completed?
    <Result status={'success'} title='Form Successfully Submitted' subTitle='You will recieve an email for further steps'/>:
  errorMessage?<Result status={'Error'} title='Submission Failed' subTitle='Try again in a few minutes'/>:
<>
      <Form onSubmit={submitForm} id='newForm' >
        {current==0?<>
        <FormItem label='Full name' required id='Full name'>
            <Input type='text' name='full-name' value={name} onChange={(e)=>{setName(e.target.value)}}/>
        </FormItem>

        <FormItem label='Sex' required id='Sex'>
            <Radio.Group value={sex} onChange={(e)=>{setSex(e.target.value)}}>
                <Radio value={'Male'}>Male</Radio>
                <Radio value={'Female'}>Female</Radio>
            </Radio.Group>
        </FormItem>

        <FormItem label='Date of birth' required id='Date of birth'>
            <Input type='date' value={dob} onChange={(e)=>{setDob(e.target.value)}}/>
        </FormItem>

        <FormItem label='Contact address in the Czech Republic' required id='Contact address in the Czech Republic'>
            <Input type='text' name='address' value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
        </FormItem>

        <FormItem label='Telephone Number' required>
            <Input type='number' name='tel' value={tel} onChange={(e)=>{setTel(e.target.value)}}/>
        </FormItem>

        <FormItem label='Email' required id='Email'>
            <Input type='email' name='email' value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
        </FormItem>

        <FormItem label='Nationality' id='Nationality' required>
            <Input placeholder='Nationality' type='text' name='nationality' value={nation} onChange={(e)=>{setNation(e.target.value)}} />
        </FormItem>

       {/* <FormItem label='Passport' >
            <Upload maxCount={1} >
            <Button icon={<Icon name='upload'/>}>Upload (Max: 1)</Button>
            </Upload>
        </FormItem>*/}

        </>
        :current==1?
<>
        <FormItem label='Hometown/Country' id='Hometown'>
            <Input type='text' name='hometown' value={home} onChange={(e)=>{setHome(e.target.value)}}/>
        </FormItem>

        <FormItem label='Profession/Occupation' id='Profession'>
            <Input type='text' name='profession' value={profession} onChange={(e)=>{setProfession(e.target.value)}}/>
        </FormItem>

        <FormItem label='Marital status at registration date' id='Marital status at registration date' >
            <Radio.Group name='Marital status at registration date' value={mStatus} onChange={(e)=>{setMStatus(e.target.value)}}>
                <Radio value={'Married'}>Married</Radio>
                <Radio value={'Single'}>Single</Radio>
                <Radio value={'Separated'}>Separated</Radio>
                <Radio value={'Divorced'}>Divorced</Radio>
                <Radio value={'Engaged'}>Engaged</Radio>
            </Radio.Group>
        </FormItem>

{mStatus=='Married'||mStatus=='Engaged'?
        <FormItem label='Spouse name' id='Spouse name'>
            <Input type='text' name='spouse name' placeholder='First Last' value={mName} onChange={(e)=>{setMName(e.target.value)}}/>
        </FormItem>
    :''
}

        <FormItem label='Number of children' id='Number of children'>
            <Radio.Group name='Number of children' value={cCount} onChange={(e)=>setCCount(e.target.value)}>
                <Radio value={'0'}>0</Radio>
                <Radio value={'1'}>1</Radio>
                <Radio value={'2'}>2</Radio>
                <Radio value={'3'}>4</Radio>
                <Radio value={'5'}>5</Radio>
            </Radio.Group>
        </FormItem>

{cCount>=1?
       <FormItem label='Children names' id='Children names'>
            <Input type='text' name='children names' placeholder='Child One, Child Two' value={cNames} onChange={(e)=>{setCnames(e.target.value)}}/>
        </FormItem>
        :''
}



</>
        


        :current==2?
        <>

        <FormItem label="Emergency contact's Full name" id="Emergency contact's Full name" required>
            <Input type='text' name='full name 2' value={ecName} onChange={(e)=>{setEcName(e.target.value)}}/>
        </FormItem>

        <FormItem label="Emergency contact's Hometown/Country" id="Emergency contact's Hometown/Country" required>
            <Input type='text' name='Hometown 2' value={ecHome} onChange={(e)=>{setEcHome(e.target.value)}}/>
        </FormItem>

        <FormItem label="Emergency contact's Telephone" id="Emergency contact's Telephone" required>
            <Input type='number' name='telephone 2' value={ecTel} onChange={(e)=>{setEcTel(e.target.value)}}/>
        </FormItem>

        <FormItem label="Emergency contact's Email" id="Emergency contact's Email" required>
            <Input type='email' name='email 2' value={ecEmail} onChange={(e)=>{setEcEmail(e.target.value)}}/>
        </FormItem>

</>


        :current==3?
<>
        <FormItem label='Membership type' required id='Membership type'>
            <Radio.Group value={memberType} onChange={(e)=>{setMemberType(e.target.value)}}>
                <Radio value={'Full Member'}>Full Member</Radio>
                <Radio value={'Associate Member'}>Associate Member</Radio>
            </Radio.Group>
        </FormItem>

        {memberType=='Associate Member'?
<>
        <FormItem label="Reasons for joining the Union" id="Reasons for joining the Union" required={memberType=='Associate Member'?true:false}>
            <TextArea name='reasons for joining union' style={{resize:'none'}} showCount value={reason} onChange={(e)=>{setReason(e.target.value)}}/>
        </FormItem>

        <Header>Endorsement by TWO Full Members</Header>
        <br/>
        <Space align='baseline'>

            <FormItem label='Full name of Member 1' id='Full name of Member 1' required={memberType=='Associate Member'?true:false}>
                <Input type='text' value={member1} onChange={(e)=>{setMember1(e.target.value)}}/>
            </FormItem>

            <FormItem label='Full name of Member 2' id='Full name of Member 2' required={memberType=='Associate Member'?true:false}>
                <Input type='text' value={member2} onChange={(e)=>{setMember2(e.target.value)}}/>
            </FormItem>
        </Space>

    </>   :''

        }



</>


    :current==4?
<>
    <FormItem label='' required id='Agreement'>
        <Header>
            I confirm that I have answered all the relevant questions fully, faithfully, and voluntarily.
            <br/>
            I also authorize Ghana Union Czech Republic to represent me or act on my behalf, without limitation, in case of emergency.
            <br/>
            By voluntarily completing this online application form, I consider this form as a binding agreement to join Ghana Union and abide by the constitution of Ghana Union.
        </Header>

        <Checkbox checked={agreement1} onChange={(e)=>{setAgreement1(e.target.checked)}}>I agree</Checkbox>
        <br/><br/>


                
        
        
    </FormItem>




</>
:current==5?
<>
<FormItem label='' required id='Agreement'>
        <Header>
        By completing this membership form I agree that Ghana Union will process and or use my data for the purpose of the Union activities.
        <br/>I also agree that the Union may publish/store on its website, WhatsApp page or any relevant location of any picture that is taken in connection with the Union activities.        
        </Header>

        <Checkbox checked={agreement2} onChange={(e)=>{setAgreement2(e.target.checked)}}>I agree</Checkbox>

        <br/><br/>


                
        
        
    </FormItem>

</>

:current==6?
<>
        <Result title='Confirm Data' extra= {current == 6?
        <>
          <Button type='primary' htmlType='submit'>Submit</Button>
        </>
         :''
          
      }
 />
{sending?<Spin tip='Submiting Form' indicator={<LoadingOutlined style={{fontSize:'4.167vh'}} spin/>} size='large' fullscreen/>:''}




</>:''


        }


      </Form>

        {current==0? '':<Button onClick={()=>{handleStepChange(0)}}>Previous</Button>}
        {current==4 || current==5 || current==6?'':<Button type='primary' onClick={()=>{handleStepChange(1)}}>Next</Button>}
        {current==4 && agreement1==true?<Button type='primary' onClick={()=>{handleStepChange(1)}}>Next</Button>:'' }
        {current==5 && agreement2==true?<Button type='primary' onClick={()=>{handleStepChange(1)}}>Next</Button>:'' }
        
</>
}

</div>

    </>
  )
}
