import './Styles/Navigation.css';
import './Styles/Home.css';
import './Styles/About.css';
import './Styles/NewsEvents.css';
import './Styles/Contact.css';
import './Styles/Gallery.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import Home from './Pages/Home';
import Navigation from './Components/Navigation';
import Executives from './Pages/Executives';
import Gallery from './Pages/Gallery';
import Contacts from './Components/Contacts';
import EventsPage from './Pages/Events';
import NewsPage from './Pages/News';
import EventDetails from './Pages/EventDetails';
import About from './Pages/About';
import NewsDetails from './Pages/NewsDetails';
import Join from './Pages/Join';
import Register from './Pages/Register';
import EventRegister from './Pages/EventRegister';
import AboutExcec from './Pages/AboutExcec';
import FullGallery from './Pages/FullGallery';
import 'semantic-ui-css/semantic.css';
import EventGallery from './Pages/EventGallery';
import ContactForm from './Components/ContactForm';
import Form from './Pages/Form';


function App() {
  return (
      <BrowserRouter>
      <Navigation/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/execs' element={<Executives/>}/>
        <Route path='/excecs/:id' element={<AboutExcec/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/gallery/:id' element={<FullGallery/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/events' element={<EventsPage/>}/>
        <Route path='/events/:id' element={<EventsPage/>}/>
        <Route path='/news' element={<NewsPage/>}/>
        <Route path='/eventdetails/:id' element={<EventDetails/>}/>
        <Route path='/newsdetails/:id' element={<NewsDetails/>}/>
        <Route path='/joinus' element={<Join/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/eventregister/:id' element={<EventRegister/>}/>
        <Route path='/eventgallery/:id' element={<EventGallery/>}/>
        <Route path='/form' element={<Form/>}/>
      </Routes>
      <Analytics/>
      <Contacts/>
      </BrowserRouter>
  );
}

export default App;
