import React, { useState } from 'react'
import { Events } from '../Data/Events'
import { Link } from 'react-router-dom'
import { Zoom } from 'react-awesome-reveal'
import { Divider, Container, Icon, Menu, MenuItem } from 'semantic-ui-react'
import bannerPic1 from '../Pictures/banner1.jpg'
import { Button, Calendar } from 'antd'



export default function News() {

  console.log(Events[0].sort.getFullYear())
  let bannerStyle ={
    backgroundImage: `url(${bannerPic1})`,
  }
  const [past,setPast] = useState(false)

  const [year,setYear] = useState()

  const sortedEvents = [...Events]
  sortedEvents.sort((a,b)=>(past?b.sort-a.sort:a.sort-b.sort))

  const yearEvents = [...Events]
  const filterYear = ()=>{
      if(past===true || past===false){
    return sortedEvents.sort((a,b)=>(past?b.sort-a.sort:a.sort-b.sort))
  } else if(year){
    return sortedEvents.filter((y)=>y.sort.getFullYear()===year)
  }
}
  console.log(filterYear())

/*
const change=()=>{
  past?setPast(false):setPast(true)
}
*/

const [calendarView,setCalendarView] = useState()

const toggleView = ()=>{
  if(calendarView==false){
    setCalendarView(true)
  }else{
    setCalendarView(false)
  }
}

// event map
  const events = filterYear().map((Event)=>
    <>
    <Zoom triggerOnce={true}>
      <Link to={`/eventdetails/${Event.id}`}  style={{display:`${past===true?`${Event.sort>new Date()? 'none':''}`:past===false?`${Event.sort<new Date()? 'none':''}`:year?`${Event.sort===`${year}`?'none':''}`:''}`,color:'black'
}} reloadDocument key={Event.id} >
    <div id='events'>
    {Event.image?<img alt='thumbnail' src={Event.image} width='200px' height={'200px'} loading='lazy' className='eventImage'/>:<></>}
    <h1 >{Event.event}</h1>
    <h4>{Event.location?<Icon name='map'/>:<></>}{Event.location}</h4>
    <h4 style={{color:Event.date==='TBA'?'gray':'black'}}><Icon name='calendar'/>{Event.date}</h4>
    <h4 style={{color:Event.time==='TBA'?'gray':'black'}}><Icon name='time'/>{Event.time}</h4>
    
    </div>
    <br/>
    <br/>



    </Link>
</Zoom>
    </>
    
  )


 



  return (
    <div>
      <div className='pageBanner' style={bannerStyle}>
        <div className='pageBannerText'>
          <Divider horizontal style={{color:'white'}}><h1>Events</h1></Divider>
          <br/>
          <a id='joinUs' onClick={()=>{setPast(false)}} style={{marginRight:'1.042vw'}} href={'#events'}>Upcoming Events</a>
          <a id='joinUs' onClick={()=>{setPast(true)}} href={'#events'}>Past Events</a>
          <br/><br/>
<br/><br/>
          <a href='#events' rel='noreferrer' style={{textDecoration:'none'}}><Icon name='arrow circle down' link  size='big' ></Icon></a>

        </div>
      </div>

      
        
      <br/>
      <div textAlign='center' style={{paddingTop:'2.604vw'}} id='events'>
        <br/>
        <br/>
        <Divider horizontal>{past===true?<h2>Past Events</h2>:past===false?<h2>Upcoming Events</h2>:year?<h2>{year} Events</h2>:''}</Divider>
      <Container  id='event'>{events.length===0?<h1>No Events</h1>:events}
      </Container>



        {past===true?
        <div style={{textAlign:'center',marginTop:'2.604vw'}}>
        <Menu pagination stackable>
            <MenuItem name='2023' className='pagination' active={year===2023} onClick={()=>{setYear(2023);setPast('')}} href={'#events'}>2023</MenuItem>
            <MenuItem name='2024' className='pagination' active={year===2024} onClick={()=>{setYear(2024);setPast('')}} href={'#events'}>2024</MenuItem>
          </Menu>

      </div>:
      <div style={{textAlign:'center',marginTop:'50px'}}>
        <Menu pagination stackable>
            <MenuItem name='2023' className='pagination' active={year===2023} onClick={()=>{setYear(2023);setPast('')}} href={'#events'}>2023</MenuItem>
            <MenuItem name='2024' className='pagination' active={year===2024} onClick={()=>{setYear(2024);setPast('')}} href={'#events'}>2024</MenuItem>
          </Menu>

      </div>}


      </div>


    </div>
  )
}
