import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Divider,Icon, Message } from 'semantic-ui-react'
import bannerPic1 from '../Pictures/banner1.jpg'
import membershipForm from '../Data/Registration Form.DOCX'


export default function Join() {
  let bannerStyle ={
    backgroundImage: `url(${bannerPic1})`,
  }



  return (
<>
<div className='pageBanner' style={bannerStyle}>
        <div className='pageBannerText'>
          <Divider horizontal style={{color:'white'}}><h1>Membership Details</h1></Divider>
          <br/>
          <a href='#memberDetails' rel='noreferrer' style={{textDecoration:'none'}}><Icon name='arrow circle down' link  size='big' ></Icon></a>

        </div>
      </div>

<div id='shortcut'>

          <Container id='join'>
      <div id='memberDetails'style={{paddingTop:'80px'}}>
      <Divider horizontal><h1>What We Do</h1></Divider>
          <li>Support for members in case of marriage, childbirth, bereavement, hospitalization, etc. </li>
          <li>We witness/endorse the Ghana passport application form for all Ghanaians when acquiring a new or renewing an existing passport </li>
          <li>We organize educational, integration and healthcare programs relevant to our stay in the Czech Republic</li>
          <li>Liaise between the Ghana community and the Ghana embassy </li>
          <li>Organize social programs such as sports, get together, etc. to acquaint members within the Ghana community</li>
          <li>Adhoc support for members in various scenarios - legal, immigration, etc.</li>
          <br/>

      <Divider horizontal><h1>Membership Dues</h1></Divider>
<p>Registration fee: <span style={{fontWeight:'bold',color:'red'}}>200 CZK</span></p>
<p>Monthly Dues: <span style={{fontWeight:'bold',color:'red'}}>100 CZK</span></p>
      </div>
      <br/><br/>
      
    <Link to={'/form'}reloadDocument>Online Registration</Link>
    {/*<Link to={'/form'} reloadDocument>Online Registration</Link>*/}
    <br/>
    <Divider horizontal>Or</Divider>
    <a href={membershipForm} download>Download Form</a>

    </Container>
  </div>
    </>
  )
}
