import React from 'react'
import { useState,useEffect } from 'react'
import emailjs, { send } from '@emailjs/browser';
import {Form,Input,FormField,FormGroup,Divider,Message,Button,Loader} from 'semantic-ui-react'

export default function ContactForm() {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null); 
  const [isError,setIsError] = useState(false)


   const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.GMAIL_SERVICE_ID,
        process.env.FORM_TEMPLATE_ID,
        e.target,
        process.env.EMAILJS_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result)
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setIsError(false)
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); 
        },
        (error) => {
          console.log(error)
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setIsError(true)
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); 
        }
      );
    
    e.target.reset();
  };

  return (
    <div id='form'>
      <Divider horizontal style={{color:'white'}}><h2>Write To Us</h2></Divider>
          <Form  onSubmit={sendEmail}>
<FormGroup unstackable widths={'equal'}>
<FormField control={'input'}  label='Your Email'  width={'5'}
  id="email"
  type="email" 
  name="user_email"
  
  required={true}
/>


<FormField control={'input'} label='Your Name'  width={'5'}
id='name'
type='text'
name='user_name'
/>
</FormGroup>

<FormField control={'textarea'} label='Your Message' inline
  id="message"
  name="user_message"
  placeholder='Type your message here'
  required={true}

 
/>

{ isSubmitting?<Loader active/>:<button type="submit" className='button-2' disabled={isSubmitting}>Send Message</button>}
      {stateMessage && <Message error={isError} positive={isError===false}>{stateMessage}</Message>}
</Form>
    </div>
  )
}
