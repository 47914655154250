import React from 'react'
import { Events } from '../Data/Events'
import { useParams } from 'react-router-dom'

export default function EventRegister() {
    const {id} = useParams()
  return (
    <div>
    <div>{Events[Number(id)-1].form}</div>
    </div>
  )
}
