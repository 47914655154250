import React, { useEffect } from 'react'
import { Zoom } from 'react-awesome-reveal'
import { Icon, Divider } from 'semantic-ui-react'
import bannerPic1 from '../Pictures/banner1.jpg'
import logo from '../Pictures/logo.jpg'
import { Executives as executives } from '../Data/Executives'

export default function About() {

  let bannerStyle ={
    backgroundImage: `url(${bannerPic1})`,
  }

  const execs = executives.map((exec)=>
  <Zoom triggerOnce key={exec.id}>
  <li><img alt='portrait' src={exec.portrait} style={{width:'127px',borderRadius:'10px',height:''}}/><span style={{fontSize:'24px',fontWeight:'bolder'}}>{exec.position}</span>
  <br/>
  {exec.name}
  <br/>
  <Icon name='phone'/><a href={`tel:${exec.number}`} target='_blank' rel='noreferrer'> {exec.number}</a>
  <br/>
  <br/>
  </li>
  </Zoom>
)

  return (

    <>
  <div className='pageBanner' style={bannerStyle}>
        <div className='pageBannerText'>
          <Divider horizontal style={{color:'white'}}><h1>About Us</h1></Divider>
          <br/>
          <a href='#executives' rel='noreferrer' style={{textDecoration:'none',color:'gold'}}>Executives<Icon name='arrow circle down' link size='big'/></a>
        </div>
      </div>


    <div id='about' style={{marginTop:'50px',textAlign:'left'}}>

  <Zoom triggerOnce>
    <div id='who'>
      <h1 style={{textAlign:'center',color:'black'}}>About Us</h1>
      <p>
      The history of Ghana Union Czech Republic (Ghana Union) dates back to 2009 and was registered in October 2013 with the Czech Ministry of trade. Before this time there had been several attempts to establish a Union for Ghanaians.
      </p>

      <br/>

      <p>
      Ghana Union is the official voice or legal body as a representative of the Ghanaian community in the Czech Republic.
      </p>

      <br/>

      <p>
      The Ghana Union Czech Republic is a registered welfare, non-profit, and non-governmental association which is governed through a constitution and by-laws by elected Executives committee.
      </p>

      <br/>

      <p>
      There is Chapter in Brno in the South Moravian Region with two co-ordinators.
The activities  of the Union is financed through  initial seed money contributions by members , membership subcriptions and dues, voluntary donations by organizations and individuals as well as fund-raising activities.

      </p>
    </div>
  </Zoom>

  <Zoom triggerOnce>
    <div id='aims'>
      <h1 style={{textAlign:'center',color:'black'}}>Aims Of The Union</h1>
      <p>
      The main aims and objectives of Ghana Union Czech Republic is to:
      </p>
      <br/>
      <ul>
        <li>Unite Ghanaians and Czechs of Ghanaian heritage</li>
        <li>Enhance awareness and essence of care for each other</li>
        <li>Recognize the achievements of its members and associates</li>
        <li>Enhance the cordial relationship between Ghana and the Czech Republic</li>
        <li>To support the socio-economic development of Ghana etc.</li>
      </ul>
    </div>
  </Zoom>

  <Zoom triggerOnce>
    <div id='activities'>
      <h1 style={{textAlign:'center',color:'black'}}>Activities Of The Union</h1>
      <p>
      Ghana Union CZ was established to foster good relationship among its members, the entire
      Ghanaian community, and Czechs of Ghanaian heritage in the Czech Republic. It also co-operate with
      other communities, public and private organizations and establishments. 
      </p>
      <br/>
      <p>Some of the activites we involve ourselves includes:</p>
      <br/>
      <ul>
        <li>Periodic general meetings</li>
        <li>Organizing seminars on crucial topics for members</li>
        <li>Voluntary activities</li>
        <li>Commemoration of the Independence Day of Ghana and other National days</li>
        <li>Other activities which is a way to fratanize with members and the Ghanaian community in the Czech Republic such as get together, sports, travel to tourist</li>
        <li>Co-operates with the Ghana embassy in the Czech Republic</li>
      </ul>
    </div>
  </Zoom>


   

  <div id='executives' style={{paddingTop:'80px'}}>
       <Divider horizontal><h1>Executives</h1></Divider>
       <br/>    
        {execs}
      </div>


      
      </div>

      </>
  )
}
