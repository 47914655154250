import logo from '../Pictures/logo.jpg'

export const News =[
   /* Template:
    {
        id: 1,
        image: 'https://picsum.photos/200',
        headline: ' Cras feugiat, odio non convallis interdum, nunc ante hendrerit neque, non pulvinar arcu ',
        date: 'Mar 20 2023',
        sort: new Date('Mar 20 2023'),
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras commodo ante eu orci imperdiet, vel rhoncus urna condimentum. Quisque euismod dolor ac augue imperdiet volutpat. Nulla eu efficitur enim. Nunc vulputate nunc justo, a fringilla odio laoreet eu. Nulla vehicula sodales laoreet. Morbi tempor venenatis neque sed pharetra. Etiam eget pulvinar urna. Pellentesque a venenatis urna, vitae suscipit arcu. Sed nibh augue, auctor ut lectus id, semper laoreet nisi. Phasellus porttitor risus justo, a fermentum arcu gravida quis. Nam volutpat massa et dolor laoreet, ac eleifend urna lobortis.',
    
    },
    */

    {
        id: 1,
        image: logo,
        headline: 'General Meeting March 16 2024',
        date: 'March 16 2024',
        time: '18:00',
        sort: new Date('March 16 2024'),
        location:'Via Zoom',
        details:<ul>
            <li><h3>Agenda 1</h3></li>
            <br/>
            <li><h3>Agenda 2</h3></li>
        </ul>
    },
    {
        id: 2,
        image: logo,
        headline: 'General Meeting June 22 2024',
        date: 'June 22 2024',
        time: '18:00',
        sort: new Date('June 22 2024'),
        location:'Via Zoom',
        details:<ul>
            <li><h3>Agenda 1</h3></li>
            <br/>
            <li><h3>Agenda 2</h3></li>
        </ul>
    },



]